const ServicesPage = {
  heading:
    'Building innovative, delightful and user-centric digital experiences',
  overview:
    'From strategy  to  development and scale, we design highly engaging experiences. We do that by immense attention to detail and our rich experience.\nWe’re a team of designers, developers, and brand strategists who create beautiful websites, user-friendly apps, and astounding digital experiences for our clients and their customers. Our team of experts will support your business from initial idea, all the way to market.',
  navigation: {
    prev: {
      title: 'Home',
      overview:
        'Go back to the home page, to see a quick snapshot of our services, what we stand for, glimpse of our work and much more',
    },
    next: {
      title: 'Industries',
      overview:
        'There is phenomenal digital transformation and emergence of automation in all spheres of business. We have worked across a vast range of industries and cater to the finest of technical expertise required for your business needs.',
    },
  },
};

export default ServicesPage;
